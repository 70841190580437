import { render, staticRenderFns } from "./purchase-receipts-list.vue?vue&type=template&id=f01f9200&scoped=true"
import script from "./purchase-receipts-list.vue?vue&type=script&lang=ts"
export * from "./purchase-receipts-list.vue?vue&type=script&lang=ts"
import style0 from "./purchase-receipts-list.vue?vue&type=style&index=0&id=f01f9200&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f01f9200",
  null
  
)

export default component.exports